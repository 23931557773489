import { default as draftsHLZtcf82v9Meta } from "/vercel/path0/pages/b/[id]/[accountId]/drafts.vue?macro=true";
import { default as gridEtiKofqa9eMeta } from "/vercel/path0/pages/b/[id]/[accountId]/grid.vue?macro=true";
import { default as indexjny9wKLkCvMeta } from "/vercel/path0/pages/b/[id]/[accountId]/index.vue?macro=true";
import { default as scheduledrkHWeg4ID1Meta } from "/vercel/path0/pages/b/[id]/[accountId]/scheduled.vue?macro=true";
import { default as indexl1V90X1DKYMeta } from "/vercel/path0/pages/b/[id]/index.vue?macro=true";
import { default as bYP0xNUwQ13Meta } from "/vercel/path0/pages/b.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as r9IcIxIhbaDMeta } from "/vercel/path0/pages/r.vue?macro=true";
export default [
  {
    name: bYP0xNUwQ13Meta?.name ?? "b",
    path: bYP0xNUwQ13Meta?.path ?? "/b",
    meta: bYP0xNUwQ13Meta || {},
    alias: bYP0xNUwQ13Meta?.alias || [],
    redirect: bYP0xNUwQ13Meta?.redirect,
    component: () => import("/vercel/path0/pages/b.vue").then(m => m.default || m),
    children: [
  {
    name: draftsHLZtcf82v9Meta?.name ?? "b-id-accountId-drafts",
    path: draftsHLZtcf82v9Meta?.path ?? ":id()/:accountId()/drafts",
    meta: draftsHLZtcf82v9Meta || {},
    alias: draftsHLZtcf82v9Meta?.alias || [],
    redirect: draftsHLZtcf82v9Meta?.redirect,
    component: () => import("/vercel/path0/pages/b/[id]/[accountId]/drafts.vue").then(m => m.default || m)
  },
  {
    name: gridEtiKofqa9eMeta?.name ?? "b-id-accountId-grid",
    path: gridEtiKofqa9eMeta?.path ?? ":id()/:accountId()/grid",
    meta: gridEtiKofqa9eMeta || {},
    alias: gridEtiKofqa9eMeta?.alias || [],
    redirect: gridEtiKofqa9eMeta?.redirect,
    component: () => import("/vercel/path0/pages/b/[id]/[accountId]/grid.vue").then(m => m.default || m)
  },
  {
    name: indexjny9wKLkCvMeta?.name ?? "b-id-accountId",
    path: indexjny9wKLkCvMeta?.path ?? ":id()/:accountId()",
    meta: indexjny9wKLkCvMeta || {},
    alias: indexjny9wKLkCvMeta?.alias || [],
    redirect: indexjny9wKLkCvMeta?.redirect,
    component: () => import("/vercel/path0/pages/b/[id]/[accountId]/index.vue").then(m => m.default || m)
  },
  {
    name: scheduledrkHWeg4ID1Meta?.name ?? "b-id-accountId-scheduled",
    path: scheduledrkHWeg4ID1Meta?.path ?? ":id()/:accountId()/scheduled",
    meta: scheduledrkHWeg4ID1Meta || {},
    alias: scheduledrkHWeg4ID1Meta?.alias || [],
    redirect: scheduledrkHWeg4ID1Meta?.redirect,
    component: () => import("/vercel/path0/pages/b/[id]/[accountId]/scheduled.vue").then(m => m.default || m)
  },
  {
    name: indexl1V90X1DKYMeta?.name ?? "b-id",
    path: indexl1V90X1DKYMeta?.path ?? ":id()",
    meta: indexl1V90X1DKYMeta || {},
    alias: indexl1V90X1DKYMeta?.alias || [],
    redirect: indexl1V90X1DKYMeta?.redirect,
    component: () => import("/vercel/path0/pages/b/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: r9IcIxIhbaDMeta?.name ?? "r",
    path: r9IcIxIhbaDMeta?.path ?? "/r",
    meta: r9IcIxIhbaDMeta || {},
    alias: r9IcIxIhbaDMeta?.alias || [],
    redirect: r9IcIxIhbaDMeta?.redirect,
    component: () => import("/vercel/path0/pages/r.vue").then(m => m.default || m)
  }
]