// Add type for mixpanel to stop errors
declare const window: Window &
  typeof globalThis & {
    mixpanel: any
  }

type Mixpanel = {
  action: string,
  properties?: Object
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  return {
    provide: {
      trackMixpanel: ({ action, properties }: Mixpanel) => {
        if (config.public.mixpanel && window.mixpanel) {
          window.mixpanel.init(config.public.mixpanel, { debug: true })
          window.mixpanel.track(action, properties)
        }
      }
    }
  }
})
