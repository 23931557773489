<template>
  <div class="flex items-center justify-center">
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="9.6" fill="#86BEEB" />
      <path d="M6.58439 25.4184V23.8584H9.31439C9.68879 23.8584 10.0164 23.7856 10.2972 23.64C10.5884 23.484 10.8172 23.2708 10.9836 23.0004C11.15 22.7196 11.2332 22.392 11.2332 22.0176C11.2332 21.6432 11.15 21.3208 10.9836 21.0504C10.8172 20.7696 10.5884 20.5564 10.2972 20.4108C10.0164 20.2548 9.68879 20.1768 9.31439 20.1768H6.58439V18.6168H9.40799C10.0944 18.6168 10.708 18.7572 11.2488 19.038C11.8 19.3188 12.2316 19.714 12.5436 20.2236C12.866 20.7332 13.0272 21.3312 13.0272 22.0176C13.0272 22.704 12.866 23.302 12.5436 23.8116C12.2316 24.3212 11.8 24.7164 11.2488 24.9972C10.708 25.278 10.0944 25.4184 9.40799 25.4184H6.58439ZM5.35199 29.5368V18.6168H7.14599V29.5368H5.35199Z" fill="white" />
      <path d="M14.5328 29.5368V18.3048H16.2488V29.5368H14.5328Z" fill="white" />
      <path d="M21.2942 29.6928C20.6182 29.6928 20.0046 29.5212 19.4534 29.178C18.9126 28.8348 18.481 28.372 18.1586 27.7896C17.8466 27.1968 17.6906 26.5364 17.6906 25.8084C17.6906 25.07 17.8466 24.4096 18.1586 23.8272C18.481 23.2344 18.9126 22.7664 19.4534 22.4232C20.0046 22.0696 20.6182 21.8928 21.2942 21.8928C21.8662 21.8928 22.3706 22.0176 22.8074 22.2672C23.2546 22.5064 23.6082 22.8392 23.8682 23.2656C24.1282 23.692 24.2582 24.1756 24.2582 24.7164V26.8692C24.2582 27.41 24.1282 27.8936 23.8682 28.32C23.6186 28.7464 23.2702 29.0844 22.823 29.334C22.3758 29.5732 21.8662 29.6928 21.2942 29.6928ZM21.575 28.0704C22.2094 28.0704 22.719 27.8572 23.1038 27.4308C23.499 27.0044 23.6966 26.4584 23.6966 25.7928C23.6966 25.3456 23.6082 24.9504 23.4314 24.6072C23.2546 24.264 23.005 23.9988 22.6826 23.8116C22.3706 23.614 22.0014 23.5152 21.575 23.5152C21.159 23.5152 20.7898 23.614 20.4674 23.8116C20.1554 23.9988 19.9058 24.264 19.7186 24.6072C19.5418 24.9504 19.4534 25.3456 19.4534 25.7928C19.4534 26.24 19.5418 26.6352 19.7186 26.9784C19.9058 27.3216 20.1554 27.592 20.4674 27.7896C20.7898 27.9768 21.159 28.0704 21.575 28.0704ZM23.5874 29.5368V27.5244L23.8838 25.6992L23.5874 23.8896V22.0488H25.3034V29.5368H23.5874Z" fill="white" />
      <path d="M32.3402 29.5368V25.2156C32.3402 24.7164 32.179 24.3056 31.8566 23.9832C31.5446 23.6608 31.139 23.4996 30.6398 23.4996C30.307 23.4996 30.0106 23.5724 29.7506 23.718C29.4906 23.8636 29.2878 24.0664 29.1422 24.3264C28.9966 24.5864 28.9238 24.8828 28.9238 25.2156L28.253 24.8412C28.253 24.2692 28.3778 23.7648 28.6274 23.328C28.877 22.8808 29.2202 22.5324 29.657 22.2828C30.1042 22.0228 30.6034 21.8928 31.1546 21.8928C31.7162 21.8928 32.2154 22.0384 32.6522 22.3296C33.089 22.6104 33.4322 22.9796 33.6818 23.4372C33.9314 23.8844 34.0562 24.3524 34.0562 24.8412V29.5368H32.3402ZM27.2078 29.5368V22.0488H28.9238V29.5368H27.2078Z" fill="white" />
      <path d="M40.9324 29.5368V25.2156C40.9324 24.7164 40.7712 24.3056 40.4488 23.9832C40.1368 23.6608 39.7312 23.4996 39.232 23.4996C38.8992 23.4996 38.6028 23.5724 38.3428 23.718C38.0828 23.8636 37.88 24.0664 37.7344 24.3264C37.5888 24.5864 37.516 24.8828 37.516 25.2156L36.8452 24.8412C36.8452 24.2692 36.97 23.7648 37.2196 23.328C37.4692 22.8808 37.8124 22.5324 38.2492 22.2828C38.6964 22.0228 39.1956 21.8928 39.7468 21.8928C40.3084 21.8928 40.8076 22.0384 41.2444 22.3296C41.6812 22.6104 42.0244 22.9796 42.274 23.4372C42.5236 23.8844 42.6484 24.3524 42.6484 24.8412V29.5368H40.9324ZM35.8 29.5368V22.0488H37.516V29.5368H35.8Z" fill="white" />
    </svg>
  </div>
</template>
