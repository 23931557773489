import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBoardIcon, LazyCommentIcon, LazyFacebookIcon, LazyGridAllIcon, LazyGridScheduledIcon, LazyInstagramGridIcon, LazyInstagramIcon, LazyInstagramPostIcon, LazyInstagramReelsIcon, LazyInstagramStoryIcon, LazyLightbulbIcon, LazyLinkIcon, LazyLinkedinIcon, LazyNoteIcon, LazyPinterestGridIcon, LazyPinterestIcon, LazyPlayIcon, LazyThreadsGridIcon, LazyThreadsIcon, LazyTikTokGridIcon, LazyTikTokIcon, LazyYoutubeIcon, LazyLlamaBlue, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["BoardIcon", LazyBoardIcon],
["CommentIcon", LazyCommentIcon],
["FacebookIcon", LazyFacebookIcon],
["GridAllIcon", LazyGridAllIcon],
["GridScheduledIcon", LazyGridScheduledIcon],
["InstagramGridIcon", LazyInstagramGridIcon],
["InstagramIcon", LazyInstagramIcon],
["InstagramPostIcon", LazyInstagramPostIcon],
["InstagramReelsIcon", LazyInstagramReelsIcon],
["InstagramStoryIcon", LazyInstagramStoryIcon],
["LightbulbIcon", LazyLightbulbIcon],
["LinkIcon", LazyLinkIcon],
["LinkedinIcon", LazyLinkedinIcon],
["NoteIcon", LazyNoteIcon],
["PinterestGridIcon", LazyPinterestGridIcon],
["PinterestIcon", LazyPinterestIcon],
["PlayIcon", LazyPlayIcon],
["ThreadsGridIcon", LazyThreadsGridIcon],
["ThreadsIcon", LazyThreadsIcon],
["TikTokGridIcon", LazyTikTokGridIcon],
["TikTokIcon", LazyTikTokIcon],
["YoutubeIcon", LazyYoutubeIcon],
["LlamaBlue", LazyLlamaBlue],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
