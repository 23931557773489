import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'

const dayjsFunc = dayjs
dayjsFunc.extend(utc)
dayjsFunc.extend(timezone)
dayjsFunc.extend(advancedFormat)
dayjsFunc.extend(localizedFormat)

export default defineNuxtPlugin(() => {
  return {
    provide: {
      dayjs: dayjsFunc
    }
  }
})
